import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Dating App Template | Dating App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/dating-mobile-app/"
    metaDescription="Introducing Match Maker, our dating app design template for mobile. Match Maker comes with all the must-haves for any self-respecting dating app."
    description="
    h2:Say hello to Match Maker, Uizard's mobile dating app design template
    <br/>
    Introducing Match Maker, our mobile dating app design template, the perfect match for all your matchmaking app design needs. Packed with all the must-have features for any self-respecting dating app design, Match Maker is sure to charm the socks off your prospective users.
    <br/>
    h3:Swipe right to expedite your design process
    <br/>
    The world of dating can be fast; designing with Uizard is even faster. To get started with our Match Maker mobile dating app design template, simply sign up to Uizard, open the <a:https://uizard.io/templates/>UI template</a> and get to work. You could have your dating app design completed in minutes.
    <br/>
    h3:Fall in love with endless customization
    <br/>
    Our Match Maker template comes packed with all the must have screens and interactions for your own dating app design however, if you want to get creative, Uizard is packed with customization features, meaning you can put your own spin on your <a:https://uizard.io/prototyping/>UI prototype design</a> in seconds.
    "
    pages={[
      "Stunning app sign up and log in pages with pin log in functionality demonstrated",
      "User profile features with form data and image upload screens",
      "Search functionality with 'match' profile pages",
      "CTAs demonstrating 'swipe left' and 'swipe right' mirroring the most popular dating apps out there on the market",
      "Instant message interface to demonstrate how your prospective users will be interacting with each other on a minute-to-minute basis",
    ]}
    projectCode="aBjlL0OQaKig4vP3M8lj"
    img1={data.image1.childImageSharp}
    img1alt="mobile dating app design overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile dating app design matches screen"
    img3={data.image3.childImageSharp}
    img3alt="mobile dating app design matched screen"
    img4={data.image4.childImageSharp}
    img4alt="mobile dating app design chat screen"
    img5={data.image5.childImageSharp}
    img5alt="mobile dating app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/dating-mobile-app/dating-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/dating-mobile-app/dating-app-matches.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/dating-mobile-app/dating-app-matched.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/dating-mobile-app/dating-app-chat.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/dating-mobile-app/dating-app-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
